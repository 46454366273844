import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const TOTAL_PIECES = 220;

const PuzzlePiece = ({ shouldRemove, index }) => {
  const pieceClass = shouldRemove ? 'piece remove' : 'piece';
  const style = {
    transform: `scale(${shouldRemove ? 6 : 1})`,
    opacity: shouldRemove ? 0 : 1,
    transitionDelay: `${Math.random() * 100 + Math.random() * 500}ms`,
  };

  return <div className={pieceClass} style={style} />;
};

export const Puzzle = forwardRef(({ donation }, ref) => {
  const [totalRows, setTotalRows] = useState(0);
  const [totalColumns, setTotalColumns] = useState(0);
  const puzzleRef = useRef();
  const [pieces, setPieces] = useState(
    localStorage.getItem('pieces')
      ? JSON.parse(localStorage.getItem('pieces'))
      : Array(TOTAL_PIECES).fill(true)
  );
  const [remaining, setRemaining] = useState([...Array(TOTAL_PIECES).keys()]);

  useImperativeHandle(ref, () => ({
    onValueChange(value) {
      const piecesToRemove = Math.floor(value / 100);
      const newPieces = [...pieces];
      const newRemaining = [...remaining];

      for (let i = 0; i < piecesToRemove; i++) {
        if (newRemaining.length > 0) {
          const randomIndex = Math.floor(Math.random() * newRemaining.length);
          newPieces[newRemaining[randomIndex]] = false;
          newRemaining.splice(randomIndex, 1);
        }
      }
      setPieces(newPieces);
      setRemaining(newRemaining);
    },
  }));

  useEffect(() => {
    localStorage.setItem('donation', donation);
    localStorage.setItem('pieces', JSON.stringify(pieces));
  }, [donation, pieces]);

  useEffect(() => {
    // const { offsetWidth } = puzzleRef.current;
    const columnsSize = 10;
    const rowSize = 22;
    setTotalColumns(columnsSize);
    setTotalRows(rowSize);
  }, []);

  return (
    <div className='puzzle' ref={puzzleRef}>
      {[...Array(totalRows)].map((_, rowIndex) => (
        <div className='puzzle-row' key={rowIndex}>
          {[...Array(totalColumns)].map((_, colIndex) => {
            const index = rowIndex * totalColumns + colIndex;
            return (
              <PuzzlePiece
                index={index}
                key={index}
                shouldRemove={!pieces[index]}
              />
            );
          })}
        </div>
      ))}
      <div className='puzzle-row last-row'>
        {[...Array(54)].map((_, colIndex) => {
          const index = 141 * totalColumns + colIndex;
          return (
            <PuzzlePiece
              index={index}
              key={index}
              shouldRemove={!pieces[index]}
            />
          );
        })}
      </div>
    </div>
  );
});

import React, { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { Puzzle } from './Puzzle';

const audio = new Audio('/sound.wav');

const initialDonationValue = localStorage.getItem('donation')
  ? Number(localStorage.getItem('donation'))
  : 0;

const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatter = new Intl.NumberFormat('en-US');

const App = () => {
  const [inputValue, setInputValue] = useState('');
  const [donation, setDonation] = useState(initialDonationValue);
  const puzzleRef = useRef();
  const [resetCount, setResetCount] = useState(0);

  const handleInputChange = (value) => {
    const val = Number(value);
    if (!isNaN(val)) {
      setInputValue(val);
    }
  };

  const handleButtonClick = () => {
    if (inputValue % 100 !== 0) {
      alert('Sadece $100 ve katlarını girebilirsiniz!');
      return;
    }

    setDonation((d) => Number(d) + Number(inputValue));
    puzzleRef.current.onValueChange(inputValue);
    setTimeout(() => {
      setInputValue('');
      audio.play();
    }, 0);
  };

  const onResetClick = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    localStorage.setItem('donation', donation);
  }, [donation]);

  const remainingAmount = 22000 - donation;
  const remainingBrickCount = remainingAmount / 100;

  return (
    <div className='app'>
      <div className='sidebar'>
        <div style={{ marginBottom: '20px' }}>
          <img src='/img/logo.jpeg' width={220} alt='logo' />
        </div>

        <input
          id='donation-input'
          name='donation'
          className='amount-input'
          type='number'
          placeholder='$0'
          value={inputValue ?? ''}
          onChange={(e) => handleInputChange(e.target.value)}
          prefix='$'
        />
        <button className='button' onClick={handleButtonClick}>
          Bağış Yap
        </button>
        <div className='piece-wrapper'>
          <div className='piece big' /> = $100
        </div>
        <br />
        <br />
        <h2>TOPLAM BAĞIŞ</h2>
        <p className='green'>{formatterCurrency.format(donation)}</p>
        {remainingAmount > 0 && (
          <>
            <h2>HEDEFE KALAN MİKTAR</h2>
            <p className='red'>{formatterCurrency.format(remainingAmount)}</p>
          </>
        )}
        <h2>KALAN TUĞLA SAYISI</h2>
        <br />
        <div className='piece-wrapper'>
          <div className='piece big' /> ={' '}
          <p style={{ margin: 0 }}>{formatter.format(remainingBrickCount)}</p>
        </div>
        <br />
        <br />
        <img src='/img/zelle.jpeg' style={{ maxWidth: '100%' }} />
        <br />
        <img src='/img/socal-logo.png' width={220} alt='socal' />
        <br />
        <button className='setting-btn' onClick={onResetClick}>
          Hedefi Sıfırla
        </button>
      </div>

      <Puzzle ref={puzzleRef} donation={donation} />

      {donation >= 1500000 && (
        <Confetti
          numberOfPieces={1000}
          width={window.innerWidth}
          height={window.innerHeight}
        />
      )}
    </div>
  );
};

export default App;
